import React from 'react';
import classes from './Dashboard.module.css';
import { useEffect, useState } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import axios from 'axios';
import { Paper, Typography } from '@mui/material';

const Dashboard = () => {
  const [userCount, dispatchUserCount] = useState()
  const [completedCourses, dispatchCoursesCount] = useState()
  const [error, setError] = useState('')

  
  useEffect(() => {
    axios.get('https://admin.training.sampurnaskills.co.in/backend/dashboard/charts')
    .then(res => {
      //console.log(res.data[0][0]);
      dispatchUserCount(res.data[0][0].user_count);
      dispatchCoursesCount(res.data[1][0].courses_completed)
    })
    .catch(err => {
      console.log(err);
      setError(err)
    })
  }, [])
  return (
    <>
      <div>Admin</div>
      <BarChart
        xAxis={[
          {
            id: 'barCategories',
            data: ['January', 'February', 'March'],
            scaleType: 'band',
          },
        ]}
        series={[
          {
            data: [2, 5, 3],
          },
        ]}
        width={500}
        height={300}
      />
      <div style={{ display: 'flex', flexDirection: 'row'}}>
        <Paper sx={{
          width: 300, padding: 2, margin: 2
        }}><Typography variant='h6'>Total Number of Users: </Typography>{userCount}</Paper>

        <Paper sx={{
          width: 300, padding: 2, margin: 2
        }}><Typography variant='h6'>Total Number of courses completed: </Typography>{completedCourses}</Paper>

      </div>
      
    </>
    
  );
}

export default Dashboard;