import React, { useState } from 'react'
import Button from '../../UI/Button/Button';
import { NavLink, useNavigation, Link } from 'react-router-dom';
import classes from './Users.module.css';
import Card from '../../UI/Card/Card';
import { Typography } from '@mui/material';
import axios from 'axios';
import { CSVLink } from "react-csv";

const BulkUploadUser = () => {
    const navigate = useNavigation();
    const [uploadFile, setUploadFile] = useState();
    const [fileSelected, setFileSelected] = useState(false);
    
    const datafields = [
        [   "id","EmpCode", "FirstName", "MIddleName",
            "LastName", "email", "password",
            "role", "DOJ", "Progress", "Score",
            "LastLogin", "img", "primaryDept",
            "designation", "last_passed_exam_date", "course_slab", "active"
        ]] // contains header for template that would be use for bulk upload
    
    const uploadCSV = (e) => {
        //console.log(e.target.files[0]);
        setUploadFile(e.target.files[0]);
        setFileSelected(true);

    }

    const upload = (e) => {
        e.preventDefault();
        if (!fileSelected) {
            alert("Please select a file to upload first");
        }
        else {
            const formdata = new FormData()
            formdata.append('file', uploadFile)
            axios.post('https://admin.training.sampurnaskills.co.in/backend/users/bulkupload', formdata,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(res => {
                    //console.log(res);
                    alert(res.data.message)
                })
                .catch(err => {
                    console.log(err);
            })
        }
    }

    const cancelUpload = () => {
        if(fileSelected)
            alert("Are you sure you want to cancel the upload?")
        navigate('/admin/users')
    }


    return (
        <>
            <NavLink to='/admin/users'>
                <Button className={classes.back_btn}>
                    {'< Go back'}
                </Button>
            </NavLink>
            
            <Card className={classes.bulK_upload_container}>
                <Typography variant='h4'>BulkUploadUser</Typography>
                <CSVLink data={datafields} filename='user_template.csv'>Click here to download template for bulk upload</CSVLink>
                <div style={{padding: '3rem'}} >
                    <div style={{padding: '1rem'}}>
                        <input type='file' onChange={uploadCSV} accept='text/csv'/>
                    </div>
                    <div style={{ marginLeft: '1rem'}}>
                        <Button type='submit' onClick={upload} className={classes.btn_upload}>Upload File</Button>
                        <Button type='submit' onClick={cancelUpload} className={classes.btn_cancel}>Cancel Upload</Button>
                    </div>
                    </div>
            </Card>
        </>
    );
}

export default BulkUploadUser;