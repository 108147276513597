import { useContext, useEffect, useState } from 'react';
import { NavLink, useNavigation } from 'react-router-dom';
import axios from 'axios';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableVirtuoso } from 'react-virtuoso';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import classes from './Departments.module.css'
import { Typography, Box } from '@mui/material';

const columns = [
  /** Define the column you need in the table here, along with the attributes mentioned below. 
   * More attributes can be added in the fixedHeaderContent
   */
  {
    width: 300,
    label: 'Id',
    dataKey: 'id',
    numeric: true,
  },
  {
    width: 300,
    label: 'Course Department',
    dataKey: 'department',
    numeric: false,
  },
  {
    width: 300,
    label: 'Designation',
    dataKey: 'designation',
    numeric: false,
  },
  {
    width: 300,
    label: 'Role',
    dataKey: 'role',
    numeric: false,
  }
];

const Departments = () => {
  const [departments, setDepartments] = useState();

  useEffect(() => {
    axios.get('https://admin.training.sampurnaskills.co.in/backend/admin/department')
      .then(res => {
        if (res) {
          setDepartments(res.data.departments);
          //console.log(res.data);
        }
      })
      .catch(err => {
        console.error(err);
      })
  }, [])

  const fixedHeaderContent = () => {
    return (
      <TableRow className={classes.table_fixed_header}>
        {columns.map((column) => (
          <TableCell
            key={column.dataKey}
            variant="head"
            align={column.numeric || false ? 'right' : 'left'}
            style={{ width: column.width }}
            sx={{
              color: '#ffffff'
            }}
          >
            {column.label}
          </TableCell>
        ))}
      </TableRow>
    );
  }

  const rowContent = (_index, row) => {
    return (
      <>
        {columns.map((column) => (
          <TableCell
            key={column.dataKey}
            align={column.numeric || false ? 'right' : 'left'}
          >
            {row[column.dataKey]}
          </TableCell>
        ))}
      </>
    );
  }

  const addDepartmentHandler = () => {

  }

  return (
    <div>
      <NavLink to='/admin/department/add'>
        <Button
          variant="contained" className={classes.add_department_btn} startIcon={<AddIcon />} onClick={addDepartmentHandler}>
          Add Department
        </Button>
      </NavLink>
      <Box style={{
        display: 'flex',
        alignContent: 'center',
        padding: 5,
        flexDirection: 'column'
      }}>
        <Typography variant='h3'>Departments</Typography>
        <Typography variant='h6' padding={5} textAlign={'left'}>
          Note: The department shown here are not the actual department of the user, these departments are use to map the courses to users based on their role and designation.
          The actual department of the user can be seen under the User tab 
        </Typography>
        
      </Box>

      <Paper sx={{ height: 400, width: '100%', border: '2px', padding:1 }}>
        <TableVirtuoso
          data={departments}
          fixedHeaderContent={fixedHeaderContent}
          itemContent={rowContent}
        />
      </Paper>
    </div>
  );
}

export default Departments;