import { useState } from 'react'
import Button from '../../UI/Button/Button';
import { NavLink, useNavigate, Link } from 'react-router-dom';
import classes from './Syllabus.module.css';
import Card from '../../UI/Card/Card';
import { Typography } from '@mui/material';
import axios from 'axios';
import { CSVLink } from "react-csv";

const AddSyllabus = () => {
    const navigate = useNavigate();
    const [uploadFile, setUploadFile] = useState();
    const [fileSelected, setFileSelected] = useState(false);

    const datafields = [[
        "id","syllabusId", "primaryDept", "designation",
        "course_dept", "courseCode", "topic",
        "link_name", "tenure_in_days", "course_slab"
    ]] // contains header for template that would be use for bulk upload

    const uploadCSV = (e) => {
        //console.log(e.target.files[0]);
        setUploadFile(e.target.files[0]);
        setFileSelected(true);

    }

    const upload = (e) => {
        e.preventDefault();
        if (!fileSelected) {
            alert("Please select a file to upload first");
        }
        else {
            const formdata = new FormData()
            formdata.append('file', uploadFile)
            axios.post('https://admin.training.sampurnaskills.co.in/backend/addsyllabus', formdata, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(res => {
                    //console.log(res);
                    alert(res.data.message)
                })
                .catch(err => {
                    console.log(err);
                })
        }
    }

    const cancelUpload = () => {
        if (fileSelected)
            alert("Do you want to cancel the upload?");
        navigate('/admin/syllabus')
    }

    return (
        <>
            <NavLink to='/admin/syllabus'>
                <Button className={classes.back_btn}>
                    {'< Go back'}
                </Button>
            </NavLink>

            <Card className={classes.bulK_upload_container}>
                <Typography variant='h4'>BulkUpload Syllabus</Typography>
                <CSVLink data={datafields} filename='user_template.csv'>Click here to download template for bulk upload</CSVLink>
                <div style={{ padding: '3rem' }} >
                    <div style={{ padding: '1rem' }}>
                        <input type='file' onChange={uploadCSV} accept='text/csv' />
                    </div>
                    <div >
                        <Button type='submit' onClick={upload} className={classes.btn_upload}>Upload File</Button>
                        <Button type='submit' onClick={cancelUpload} className={classes.btn_cancel}>Cancel Upload</Button>
                    </div>
                </div>
            </Card>
        </>
    );
}

export default AddSyllabus;