import {useState} from 'react'
import { Paper, TextField, Box, Grid, Divider, InputLabel, NativeSelect } from '@mui/material';
import { styled } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';
import classes from './Departments.module.css';
import Button from '../../UI/Button/Button';
import axios from 'axios';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
}));

const initialData = {
    'department': '',
    'role': '',
    'designation':''
}


export const AddDepartment = () => {
    const [departmentData, SetDepartmentData] = useState(initialData)

    const change = (name, value) => {
        SetDepartmentData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const addDepartmentHandler = () => {
        console.log(departmentData);

        const { department, role, designation } = departmentData;
        const values = {
            'department': department,
            'role': role,
            'designation': designation
        }

        axios.post('https://admin.training.sampurnaskills.co.in/backend/admin/addDepartment', values)
            .then(res => {
                //console.log(res.data.message);
                alert(res.data.message);
                SetDepartmentData(initialData);
            })
            .catch(err => {
                console.error(err);
            })
    }

    return (
        <>
            <NavLink to='/admin/department'>
                <Button className={classes.back_btn}>
                    {"< Go back"}
                </Button>
            </NavLink>
            <Paper sx={{ padding: 3 }}>
                
                <div className={classes.heading_add_user}>
                    <p><b>Add Department</b></p>
                </div>
                <Box sx={{ flexGrow: 2, padding:1, margin:1 }}>
                    <form>
                        <div className={classes.inputFieldContainer}>
                            <p>Add Department, Role and designation</p>
                            <Grid container spacing={4} padding={2}>
                                <Grid item xs={8} md={4}>
                                    <Item>
                                        <TextField type='text' id='department' label='Department' onChange={(e) => { change('department', e.target.value) }} fullWidth />    
                                    </Item>
                                </Grid>
                                <Grid item xs={8} md={4}>
                                    <Item>
                                        <TextField type='text' id='role' label='Role' onChange={(e) => { change('role', e.target.value) }} fullWidth />
                                    </Item>
                                </Grid>
                                <Grid item xs={8} md={4}>
                                    <Item>
                                        <TextField type='text' id='designation' label='Designation' onChange={(e) => { change('designation', e.target.value) }} fullWidth />
                                    </Item>
                                </Grid>
                            </Grid>
                            <Divider />
                        </div>
                    </form>
                    <Button type='submit' variant="contained" onClick={addDepartmentHandler}>Submit</Button>
                </Box>
            </Paper>
        </>
    );
}
export default AddDepartment;
